.wrapper {
}

.wrapper ul {
}

.wrapper li {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
  margin-left: 39px;
  width: 213px;
  border: 12px;
  border-radius: 9px;
  counter-increment: item 1;
  margin-bottom: 8px;
}

.wrapper ul li::before {
  content: counter(item);
  position: absolute;
  left: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #ffffff;
  font-size: 14px;
  background-color: var(--color-blue);
}
