@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body,
html {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader img {
  animation: rotate 1s linear infinite;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 100dvh;
}

:root {
  --color-text: #6e7caf;
  --color-red: #E53535;
  --color-gray: #ddf0e2;
  --color-blue: #567bff;
  --color-border: #d7e0ff;
  --color-light-blue: #f4f6ff;
  --color-white: #ffffff;
  --color-green: #0eaf00;
  --color-gold-light: rgba(251, 236, 159, 0.37);
  --color-gold-dark: #ffcd6d;
  --color-input: #fffcef;
  --border-radius: 10px;
  --border-radius-2: 4px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
  color: inherit;
}

button {
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

*:disabled {
  cursor: not-allowed;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  hyphens: auto;
}

img {
  max-width: 100%;
}
